const recos = {
  list: {
    priceSizeOverride: {
      price: 24,
      discount: 12,
      installments: 12,
      original: 12,
    },
  },
};

export const BUTTON_SEARCH_ID = 'cart-btn-search';
export const BUTTON_LANDING = 'landing-button';
export const CARD_FEATURED_ID = 'card-featured';
export const CAROUSEL_FEATURED_ID = 'carousel-featured';
export const CARD_PURCHASED_ID = 'card-purchased';
export const EMPTY_BUTTON_CART = 'recos-empty-btn-cart';
export const EMPTY_BUTTON_SEARCH = 'recos-empty-btn-search';
export const EMPTY_SUBTITLE = 'recos-empty-subtitle';
export const EMPTY_TITLE = 'recos-empty-title';
export const FREE_SHIPPING_RECOS_ID = 'free-shipping-cart-recommendations';
export const MLT_RECOS_ID = 'more-like-this-recommendations';
export const PERSO_RECOS_ID = 'perso-landing';
export const POM_RECOS_ID = 'pom-recommendations';
export const PROGRESSBAR_ID = 'cart-full-progress';
export const RECOS = recos;
export const RMKT_RECOS_ID = 'remarketing-cart-recommendations';
export const CAROUSEL_ID = 'carousels';
export const LIST_RECOMMENDATIONS = 'list-recommendations';
export const HOME_RECOS_ID = 'home-landing-recommendations';
export const SUBTITLE_ID = 'cart-subtitle';
export const TABS_RECOS = 'tabs';
export const RECOS_ERROR_HANDLED = 'recos-error-handled';
export const RECOS_EMPTY_STATE = 'recos-empty-state';
export const POM_ADN_ID = 'CONTEXT_UP';
